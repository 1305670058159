<template>
  <div class="">
    <my-header-view :title="titleT" :bread-crumbs="breadCrums" />
    <my-loader
      v-if="loading"
      title="Un momento por favor"
      subtitle="Estamos obteniendo los procesos existentes."
    />
    <div v-else class="mt-8">
      <v-expansion-panels v-model="panel" flat popout hover>
        <!--            Productos de Entrada            -->
        <v-expansion-panel :key="0" active-class="grey lighten-5 soft_shadow">
          <v-expansion-panel-header :color="panel === 0 ? 'secondary' : ''">
            <template v-slot:actions>
              <v-icon :color="panel === 0 ? 'white' : 'support'">
                $expand
              </v-icon>
            </template>
            <h3>
              <v-icon :color="panel === 0 ? 'pla_c3' : 'primary'" left>
                mdi-domain
              </v-icon>
              <span
                :class="
                  panel === 0 ? 'white--text' : 'grey--text text--darken-3'
                "
              >
                {{ "Productos de Entrada" }}
              </span>
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="pt-5">
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="productosEntrada"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{ 'sort-by-text': 'Ordenar por' }"
                  :footer-props="{
                    'items-per-page-text': 'Procesos por página',
                    'items-per-page-all-text': 'Todos',
                  }"
                >
                  <!-- slots defaults -->
                  <template v-slot:top>
                    <my-table-top enableSearch @handleSearch="handleSearch">
                      <template v-slot:options>
                        <v-row no-gutters>
                          <v-col cols="4">
                            <sync-info
                              title="los Productos de entrada"
                              :loading="loadingPE"
                              id="productsEntrada/getProcesosProductosEntrada"
                            />
                          </v-col>
                          <v-col cols="8">
                            <my-table-options
                              include-create
                              create-text="Agregar Producto de entrada"
                              @optionReport="createReport"
                              @optionCreate="openCreateForm('PRODUCTO_ENTRADA')"
                            >
                            </my-table-options>
                          </v-col>
                        </v-row>
                      </template>
                    </my-table-top>
                  </template>
                  <template v-slot:no-data>
                    <my-table-no-data
                      title="¡Lo sentimos!"
                      text="Aun no existe algun tipo proceso."
                      button
                      button-text="Agregar"
                      @button-data-action="openCreateForm('PRODUCTO_ENTRADA')"
                    />
                  </template>
                  <template v-slot:no-results>
                    <my-table-no-data
                      title="¡Lo sentimos!"
                      text="No encontramos ningun tipo proceso."
                    />
                  </template>
                  <!-- slots defaults end -->
                  <template v-slot:[`item.sadm_eliminado`]="{ item }">
                    {{ item.eliminado ? "Eliminado" : "Activo" }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <my-table-item-actions
                      :item="item"
                      no-use-edit
                      @edit-item="editItem"
                      @enable-item="enableItem"
                      @disable-item="disableItem"
                    >
                    </my-table-item-actions>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--            Insumos            -->
        <v-expansion-panel :key="1" active-class="grey lighten-5">
          <v-expansion-panel-header :color="panel === 1 ? 'secondary' : ''">
            <template v-slot:actions>
              <v-icon :color="panel === 1 ? 'white' : 'support'">
                $expand
              </v-icon>
            </template>
            <h3>
              <v-icon :color="panel === 1 ? 'pla_c3' : 'primary'" left>
                mdi-content-paste
              </v-icon>
              <span
                :class="
                  panel === 1 ? 'white--text' : 'grey--text text--darken-3'
                "
              >
                {{ "Insumos" }}</span
              >
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="pt-5">
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="procesosInsumo"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{ 'sort-by-text': 'Ordenar por' }"
                  :footer-props="{
                    'items-per-page-text': 'Procesos por página',
                    'items-per-page-all-text': 'Todos',
                  }"
                >
                  <!-- slots defaults -->
                  <template v-slot:top>
                    <my-table-top enableSearch @handleSearch="handleSearch">
                      <template v-slot:options>
                        <v-row no-gutters>
                          <v-col cols="4">
                            <sync-info
                              title="los Insumos"
                              :loading="loadingInsumos"
                              id="productsMP/getProcesosInsumos"
                            />
                          </v-col>
                          <v-col cols="8">
                            <my-table-options
                              include-create
                              create-text="Agregar Insumo"
                              @optionReport="createReport"
                              @optionCreate="openCreateForm('INSUMO')"
                            >
                            </my-table-options>
                          </v-col>
                        </v-row>
                      </template>
                    </my-table-top>
                  </template>
                  <template v-slot:no-data>
                    <my-table-no-data
                      title="¡Lo sentimos!"
                      text="Aun no existe algun insumo."
                      button
                      button-text="Agregar"
                      @button-data-action="openCreateForm('INSUMO')"
                    />
                  </template>
                  <template v-slot:no-results>
                    <my-table-no-data
                      title="¡Lo sentimos!"
                      text="No encontramos ningun insumo."
                    />
                  </template>
                  <!-- slots defaults end -->
                  <template v-slot:[`item.sadm_eliminado`]="{ item }">
                    {{ item.eliminado ? "Eliminado" : "Activo" }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <my-table-item-actions
                      :item="item"
                      no-use-edit
                      @edit-item="editItem"
                      @enable-item="enableItemInsumo"
                      @disable-item="disableItemInsumo"
                    >
                    </my-table-item-actions>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--            Material de empaque            -->
        <v-expansion-panel :key="2" active-class="grey lighten-5 soft_shadow">
          <v-expansion-panel-header :color="panel === 2 ? 'secondary' : ''">
            <template v-slot:actions>
              <v-icon :color="panel === 2 ? 'white' : 'support'">
                $expand
              </v-icon>
            </template>
            <h3>
              <v-icon :color="panel === 2 ? 'pla_c3' : 'primary'" left>
                mdi-clipboard-check
              </v-icon>
              <span
                :class="
                  panel === 2 ? 'white--text' : 'grey--text text--darken-3'
                "
              >
                {{ "Material de empaque" }}
              </span>
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="materialEmpaque"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{ 'sort-by-text': 'Ordenar por' }"
                  :footer-props="{
                    'items-per-page-text': 'Procesos por página',
                    'items-per-page-all-text': 'Todos',
                  }"
                >
                  <!-- slots defaults -->
                  <template v-slot:top>
                    <my-table-top enableSearch @handleSearch="handleSearch">
                      <template v-slot:options>
                        <v-row no-gutters>
                          <v-col cols="4">
                            <sync-info
                              title="los Materiales de empaque"
                              :loading="loadingMaterialEmpaque"
                              id="materialEmpaque/getProcesosMaterialEmpaque"
                            />
                          </v-col>
                          <v-col cols="8">
                            <my-table-options
                              include-create
                              create-text="Agregar Material de empaque"
                              @optionReport="createReport"
                              @optionCreate="openCreateForm('MATERIAL_EMPAQUE')"
                            >
                            </my-table-options>
                          </v-col>
                        </v-row>
                      </template>
                    </my-table-top>
                  </template>
                  <template v-slot:no-data>
                    <my-table-no-data
                      title="¡Lo sentimos!"
                      text="Aun no existe algun Material de empaque."
                      button
                      button-text="Agregar"
                      @button-data-action="openCreateForm('MATERIAL_EMPAQUE')"
                    />
                  </template>
                  <template v-slot:no-results>
                    <my-table-no-data
                      title="¡Lo sentimos!"
                      text="No encontramos ningun Material de empaque."
                    />
                  </template>
                  <!-- slots defaults end -->
                  <template v-slot:[`item.sadm_eliminado`]="{ item }">
                    {{ item.eliminado ? "Eliminado" : "Activo" }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <my-table-item-actions
                      :item="item"
                      no-use-edit
                      @edit-item="editItem"
                      @enable-item="enableItemEmpaque"
                      @disable-item="disableItemEmpaque"
                    >
                    </my-table-item-actions>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--            Maquinaria            -->
        <v-expansion-panel :key="3" active-class="grey lighten-5 soft_shadow">
          <v-expansion-panel-header :color="panel === 3 ? 'secondary' : ''">
            <template v-slot:actions>
              <v-icon :color="panel === 3 ? 'white' : 'support'">
                $expand
              </v-icon>
            </template>
            <h3>
              <v-icon :color="panel === 3 ? 'pla_c3' : 'primary'" left>
                mdi-forklift
              </v-icon>
              <span
                :class="
                  panel === 3 ? 'white--text' : 'grey--text text--darken-3'
                "
              >
                {{ "Maquinaria" }}
              </span>
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="pt-5">
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="maquinaria"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{ 'sort-by-text': 'Ordenar por' }"
                  :footer-props="{
                    'items-per-page-text': 'Procesos por página',
                    'items-per-page-all-text': 'Todos',
                  }"
                >
                  <!-- slots defaults -->
                  <template v-slot:top>
                    <my-table-top enableSearch @handleSearch="handleSearch">
                      <template v-slot:options>
                        <v-row no-gutters>
                          <v-col cols="4">
                            <sync-info
                              title="las maquinas"
                              :loading="loadingMaquinaria"
                              id="productsEntrada/getProcesosMaquinas"
                            />
                          </v-col>
                          <v-col cols="8">
                            <my-table-options
                              include-create
                              create-text="Agregar Maquinaria"
                              @optionReport="createReport"
                              @optionCreate="openCreateForm('MAQUINARIA')"
                            >
                            </my-table-options>
                          </v-col>
                        </v-row>
                      </template>
                    </my-table-top>
                  </template>
                  <template v-slot:no-data>
                    <my-table-no-data
                      title="¡Lo sentimos!"
                      text="Aun no existe algun material de empaque."
                      button
                      button-text="Agregar"
                      @button-data-action="openCreateForm('MAQUINARIA')"
                    />
                  </template>
                  <template v-slot:no-results>
                    <my-table-no-data
                      title="¡Lo sentimos!"
                      text="No encontramos ningun material de empaque."
                    />
                  </template>
                  <!-- slots defaults end -->
                  <template v-slot:[`item.sadm_eliminado`]="{ item }">
                    {{ item.eliminado ? "Eliminado" : "Activo" }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <my-table-item-actions
                      :item="item"
                      @edit-item="editItem"
                      @enable-item="enableItemMaquinaria"
                      @disable-item="disableItemMaquinaria"
                    >
                    </my-table-item-actions>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!--            Productos de salida            -->
        <v-expansion-panel :key="4" active-class="grey lighten-5 soft_shadow">
          <v-expansion-panel-header :color="panel === 4 ? 'secondary' : ''">
            <template v-slot:actions>
              <v-icon :color="panel === 4 ? 'white' : 'support'">
                $expand
              </v-icon>
            </template>
            <h3>
              <v-icon :color="panel === 4 ? 'pla_c3' : 'primary'" left>
                mdi-reproduction
              </v-icon>
              <span
                :class="
                  panel === 4 ? 'white--text' : 'grey--text text--darken-3'
                "
              >
                {{ "Productos de salida" }}</span
              >
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="pt-5">
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="productosSalida"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{ 'sort-by-text': 'Ordenar por' }"
                  :footer-props="{
                    'items-per-page-text': 'Procesos por página',
                    'items-per-page-all-text': 'Todos',
                  }"
                >
                  <!-- slots defaults -->
                  <template v-slot:top>
                    <my-table-top enableSearch @handleSearch="handleSearch">
                      <template v-slot:options>
                        <v-row no-gutters>
                          <v-col cols="4">
                            <sync-info
                              title="los productos de salida"
                              :loading="loadingSalida"
                              id="productosSalida/getProcesosProductosSalida"
                            />
                          </v-col>
                          <v-col cols="8">
                            <my-table-options
                              include-create
                              create-text="Agregar Productos de salida"
                              @optionReport="createReport"
                              @optionCreate="openCreateForm('PRODUCTO_SALIDA')"
                            >
                            </my-table-options>
                          </v-col>
                        </v-row>
                      </template>
                    </my-table-top>
                  </template>
                  <template v-slot:no-data>
                    <my-table-no-data
                      title="¡Lo sentimos!"
                      text="Aun no existe algun Producto de salida."
                      button
                      button-text="Agregar"
                      @button-data-action="openCreateForm('PRODUCTO_SALIDA')"
                    />
                  </template>
                  <template v-slot:no-results>
                    <my-table-no-data
                      title="¡Lo sentimos!"
                      text="No encontramos ningun Producto de salida."
                    />
                  </template>
                  <!-- slots defaults end -->
                  <template v-slot:[`item.sadm_eliminado`]="{ item }">
                    {{ item.eliminado ? "Eliminado" : "Activo" }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <my-table-item-actions
                      :item="item"
                      no-use-edit
                      @edit-item="editItem"
                      @enable-item="enableItemSalida"
                      @disable-item="disableItemSalida"
                    >
                    </my-table-item-actions>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <products-entrada-form
      v-if="dialogForm"
      :itemIndex="editedIndex"
      :itemParam="editedItem"
      :type="tipo"
      @close="close"
    >
    </products-entrada-form>

    <productos-salida-form
      v-if="dialogPSForm"
      :itemIndex="editedIndex"
      :itemParam="editedItem"
      :type="tipo"
      @close="close"
    >
    </productos-salida-form>

    <sub-productos-salida-form
      v-if="dialogSPSForm"
      :itemIndex="editedIndex"
      :itemParam="editedItem"
      :type="tipo"
      @close="close"
    >
    </sub-productos-salida-form>

    <insumos-form
      v-if="dialogIPrForm"
      :itemIndex="editedIndex"
      :itemParam="editedItem"
      :type="tipo"
      @close="close"
    >
    </insumos-form>

    <maquinaria-form
      v-if="dialogMForm"
      :itemIndex="editedIndex"
      :itemParam="editedItem"
      :type="tipo"
      @close="close"
    >
    </maquinaria-form>

    <material-empaque-form
      v-if="dialogMEForm"
      :itemIndex="editedIndex"
      :itemParam="editedItem"
      :type="tipo"
      @close="close"
    >
    </material-empaque-form>

    <my-table-dialog-delete
      :title="updateUserTitle"
      :loadingSave="loadingSavePE"
      :dialogDelete="dialogDelete"
      @change-state="changeStateItemConfirm"
      @close-delete="closeDelete"
      @dialog-delete="(val) => (dialogDelete = val)"
    ></my-table-dialog-delete>

    <my-table-dialog-delete
      :title="updateUserTitle"
      :loadingSave="loadingSaveSalida"
      :dialogDelete="dialogDeleteSalida"
      @change-state="changeStateItemConfirmSalida"
      @close-delete="closeDelete"
      @dialog-delete="(val) => (dialogDeleteSalida = val)"
    ></my-table-dialog-delete>

    <my-table-dialog-delete
      :title="updateUserTitle"
      :loadingSave="loadingSaveMaquinaria"
      :dialogDelete="dialogDeleteMaquinaria"
      @change-state="changeStateItemConfirmMaquinaria"
      @close-delete="closeDelete"
      @dialog-delete="(val) => (dialogDeleteMaquinaria = val)"
    ></my-table-dialog-delete>

    <my-table-dialog-delete
      :title="updateUserTitle"
      :loadingSave="loadingSaveMaterialEmpaque"
      :dialogDelete="dialogDeleteEmpaque"
      @change-state="changeStateItemConfirmEmpaque"
      @close-delete="closeDelete"
      @dialog-delete="(val) => (dialogDeleteEmpaque = val)"
    ></my-table-dialog-delete>

    <my-table-dialog-delete
      :title="updateUserTitle"
      :loadingSave="loadingSaveInsumos"
      :dialogDelete="dialogDeleteInsumo"
      @change-state="changeStateItemConfirmInsumo"
      @close-delete="closeDelete"
      @dialog-delete="(val) => (dialogDeleteInsumo = val)"
    ></my-table-dialog-delete>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { milisecondsToDate, catalogReport } from "@/core/utils";
import ProductsEntradaForm from "@/components/Forms/TProcesos/ProcesosPenForm";
import SubProductosSalidaForm from "@/components/Forms/TProcesos/SubProductosSalidaForm";
import InsumosForm from "@/components/Forms/TProcesos/InsumosPrForm";
import MaterialEmpaqueForm from "@/components/Forms/TProcesos/MaterialEmpaqueForm";
import ProductosSalidaForm from "@/components/Forms/TProcesos/ProductosSalidaForm";
import MaquinariaForm from "@/components/Forms/TProcesos/MaquinariaForm";
import myHeaderView from "../../../ui/myHeaderView";
import myLoader from "../../../ui/myLoader";
import myTableTop from "../../../ui/datatable/myTableTop";
import myTableNoData from "../../../ui/datatable/myTableNoData";
import myTableOptions from "../../../ui/datatable/myTableOptions";
import myTableItemActions from "../../../ui/datatable/myTableItemActions";
import myTableDialogDelete from "../../../ui/datatable/myTableDialogDelete";
import SyncInfo from "@/components/Home/SyncInfo";

export default {
  components: {
    ProductosSalidaForm,
    SubProductosSalidaForm,
    MaquinariaForm,
    MaterialEmpaqueForm,
    InsumosForm,
    myHeaderView,
    ProductsEntradaForm,
    myLoader,
    myTableOptions,
    myTableDialogDelete,
    myTableNoData,
    myTableItemActions,
    myTableTop,
    SyncInfo,
  },
  name: "products",
  data: () => ({
    productosEntradaf: [],
    panel: 0,
    titleT: "",
    breadCrums: [
      {
        text: "Inicio",
        disabled: false,
        url: "Home",
      },
      {
        text: "Configuración",
        disabled: false,
        url: "Configuracion",
      },
      {
        text: "Catálogos",
        disabled: false,
        url: "Catalogos",
      },
      {
        text: "Tipo de procesos",
        disabled: true,
        url: "TProcesos",
      },
    ],
    dialogForm: false,
    dialogPSForm: false,
    dialogSPSForm: false,
    dialogIPrForm: false,
    dialogMEForm: false,
    dialogDelete: false,
    dialogDeleteSalida: false,
    dialogDeleteEmpaque: false,
    dialogDeleteInsumo: false,
    dialogDeleteMaquinaria: false,
    dialogMForm: false,
    headers: [
      { text: "Nombre", value: "sinv_pro_descripcion" },
      { text: "Estado", value: "sadm_eliminado" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    tipo: "",
    editedItem: {
      prprcNombre: "",
    },
    defaultItem: {
      prprcNombre: "",
    },
    estado: 0,
    search: "",
  }),

  computed: {
    ...mapState({
      productosEntrada: (state) => state.productsEntrada.productosEntradaPro,
      procesosInsumo: (state) => state.productsMP.productosMPPro,
      materialEmpaque: (state) => state.materialEmpaque.materialesEmpaqueProc,
      maquinaria: (state) => state.maquinaria.maquinariasPro,
      productosSalida: (state) => state.productosSalida.productosSalidaPro,
      items: (state) => state.tprocesos.procesos,
      loading: (state) => state.tprocesos.loading,
      loadingSave: (state) => state.tprocesos.loadingSave,
      loadingPE: (state) => state.productsEntrada.loading,
      loadingSavePE: (state) => state.productsEntrada.loadingSave,
      loadingInsumos: (state) => state.productsMP.loading,
      loadingSaveInsumos: (state) => state.productsMP.loadingSave,
      loadingMaterialEmpaque: (state) => state.materialEmpaque.loading,
      loadingSaveMaterialEmpaque: (state) => state.materialEmpaque.loadingSave,
      loadingMaquinaria: (state) => state.maquinaria.loading,
      loadingSaveMaquinaria: (state) => state.maquinaria.loadingSave,
      loadingSalida: (state) => state.productosSalida.loading,
      loadingSaveSalida: (state) => state.productosSalida.loadingSave,
    }),
    updateUserTitle() {
      return this.estado === 0
        ? "¿Desea activar este proceso?"
        : "¿Desea desactivar este proceso?";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    findProducto(arreglo, id) {
      const productoT = arreglo.find((producto) => producto.sinv_pro_id == id);
      return productoT.sinv_pro_descripcion;
    },
    openCreateForm(t = "") {
      this.tipo = t;
      switch (t) {
        case "PRODUCTO_ENTRADA":
          this.dialogForm = true;
          break;
        case "INSUMO":
          this.dialogIPrForm = true;
          break;
        case "MATERIAL_EMPAQUE":
          this.dialogMEForm = true;
          break;
        case "MAQUINARIA":
          this.dialogMForm = true;
          break;
        case "PRODUCTO_SALIDA":
          this.dialogPSForm = true;
          break;
        case "SUBPRODUCTO_SALIDA":
          this.dialogSPSForm = true;
          break;
      }
    },
    handleSearch(value) {
      this.search = value;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.openCreateForm("PRODUCTO_ENTRADA");
    },

    close() {
      this.dialogForm = false;
      this.dialogIPrForm = false;
      this.dialogMEForm = false;
      this.dialogPSForm = false;
      this.dialogSPSForm = false;
      this.dialogMForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogDeleteSalida = false;
      this.dialogDeleteEmpaque = false;
      this.dialogDeleteInsumo = false;
      this.dialogDeleteMaquinaria = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    enableItem(item) {
      this.estado = 0;
      this.editedIndex = this.productosEntrada.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    disableItem(item) {
      this.estado = 1;
      this.editedIndex = this.productosEntrada.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    enableItemSalida(item) {
      this.estado = 0;
      this.editedIndex = this.productosSalida.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteSalida = true;
    },

    disableItemSalida(item) {
      this.estado = 1;
      this.editedIndex = this.productosSalida.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteSalida = true;
    },

    enableItemEmpaque(item) {
      this.estado = 0;
      this.editedIndex = this.materialEmpaque.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteEmpaque = true;
    },

    disableItemEmpaque(item) {
      this.estado = 1;
      this.editedIndex = this.materialEmpaque.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteEmpaque = true;
    },
    enableItemInsumo(item) {
      this.estado = 0;
      this.editedIndex = this.procesosInsumo.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteInsumo = true;
    },

    disableItemInsumo(item) {
      this.estado = 1;
      this.editedIndex = this.procesosInsumo.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteInsumo = true;
    },
    enableItemMaquinaria(item) {
      this.estado = 0;
      this.editedIndex = this.maquinaria.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteMaquinaria = true;
    },

    disableItemMaquinaria(item) {
      this.estado = 1;
      this.editedIndex = this.maquinaria.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeleteMaquinaria = true;
    },

    changeStateItemConfirm() {
      let data = {
        prppeId: this.editedItem.prppeId,
        estado: this.estado === 1,
      };

      this.$store
        .dispatch("productsEntrada/deleteProductoEntrada", {
          data,
        })
        .then(() => (this.dialogDelete = false))
        .catch((error) => console.log(error));
    },
    changeStateItemConfirmSalida() {
      let data = {
        prppsId: this.editedItem.prppsId,
        estado: this.estado === 1,
      };
      this.$store
        .dispatch("productosSalida/deleteProductoSalida", {
          data,
        })
        .then(() => (this.dialogDeleteSalida = false))
        .catch((error) => console.log(error));
    },
    changeStateItemConfirmEmpaque() {
      let data = {
        prpmeId: this.editedItem.prpmeId,
        estado: this.estado === 1,
      };

      this.$store
        .dispatch("materialEmpaque/deleteMaterialEmpaque", {
          data,
        })
        .then(() => (this.dialogDeleteEmpaque = false))
        .catch((error) => console.log(error));
    },
    changeStateItemConfirmInsumo() {
      let data = {
        prpinId: this.editedItem.prpinId,
        estado: this.estado === 1,
      };

      this.$store
        .dispatch("productsMP/deleteInsumo", {
          data,
        })
        .then(() => (this.dialogDeleteInsumo = false))
        .catch((error) => console.log(error));
    },
    milisecondsToDate(miliseconds) {
      return milisecondsToDate(miliseconds);
    },
    changeStateItemConfirmMaquinaria() {
      let data = {
        prpmaId: this.editedItem.prpmaId,
        estado: this.estado === 1,
      };

      this.$store
        .dispatch("maquinaria/deleteMaquinariaProc", {
          data,
        })
        .then(() => (this.dialogDeleteMaquinaria = false))
        .catch((error) => console.log(error));
    },

    createReport() {
      const params = {
        name: "Tipos de procesos",
        moneda: "GTQ",
        columns: [
          { header: "Nombre", key: "nombre", width: 18 }, //TODO set width
          { header: "Estado", key: "estado", width: 12 },
          { header: "Creación", key: "creacion", width: 12 },
          { header: "Modificacion", key: "modificacion", width: 12 },
        ],
        data: this.getData(),
      };
      catalogReport(params);
    },

    getData() {
      return this.items.map((item) => ({
        nombre: {
          value: item.prprcNombre,
          type: "text",
          column: "A",
        },
        estado: {
          value: item.sadm_eliminado ? "Eliminado" : "Activo",
          type: "text",
          column: "B",
        },
        creacion: {
          value: milisecondsToDate(item.sadm_fecha_creado),
          type: "date",
          column: "C",
        },
        modificacion: {
          value: milisecondsToDate(item.sadm_fecha_modificado),
          type: "date",
          column: "D",
        },
      }));
    },
  },

  mounted() {
    this.titleT = localStorage.getItem("prprcNombre");
    this.$store.dispatch("tprocesos/findProcesos");
    this.$store.dispatch("productsEntrada/getProcesosProductosEntrada");
    this.$store.dispatch("productsMP/getProcesosInsumos");
    this.$store.dispatch("materialEmpaque/getProcesosMaterialEmpaque");
    this.$store.dispatch("maquinaria/getProcesosMaquinas");
    this.$store.dispatch("productosSalida/getProcesosProductosSalida");
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
