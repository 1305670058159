<template>
  <v-dialog v-model="dialog" max-width="400px" persistent>
    <v-card>
      <v-card-title class="secondary">
        <v-icon color="pla_c3"> {{ iconTitle }} </v-icon>
        <span class="text-h6 pl-3 white--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="py-2 px-5">
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" class="colForm pa-2">
                <v-autocomplete
                  v-model="item.sinv_pro_id"
                  :items="productosEntrada"
                  color="support lighten-2"
                  label="Maquina"
                  item-text="sinv_pro_descripcion"
                  item-value="sinv_pro_id"
                  clearable
                  hint="opcional"
                  persistent-hint
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn @click="close" :loading="loadingSave" color="support" outlined>
          Cancelar
        </v-btn>
        <v-btn
          @click="save"
          elevation="0"
          :loading="loadingSave"
          color="primary"
        >
          {{ buttonTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { findTipoProductoPlanilla } from "@/helpers";

export default {
  props: {
    itemIndex: Number,
    itemParam: Object,
    type: String,
  },
  data: () => ({
    valid: false,
    item: {
      sinv_pro_id: null,
      prprcId: null,
    },
    noEmptyRules: [(value) => !!value || "Completa este dato."],
    dialog: true,
  }),
  methods: {
    save() {
      if (!this.$refs.form.validate()) return;
      if (this.itemIndex > -1) {
        this.updateItem();
      } else {
        this.createItem();
      }
    },
    findTipoProductoPlanilla(tipo) {
      return findTipoProductoPlanilla(tipo);
    },
    getProduct(id) {
      return this.maquinaria.find((product) => product.sinv_pro_id == id);
    },
    createItem() {
      const data = {
        sinv_pro_id: +this.item.sinv_pro_id,
        prprcId: +this.item.prprcId,
        prpmaCostoHora: 0,
      };
      localStorage.setItem(
        `sinv_pro_descripcion_temp`,
        this.getProduct(data.sinv_pro_id).sinv_pro_descripcion
      );
      this.$store
        .dispatch("maquinaria/createProcesoMaquinas", {
          data: data,
        })
        .then(() => this.close())
        .catch((error) => console.log(error));
    },
    updateItem() {
      this.$store
        .dispatch("tprocesos/updateProceso", {
          data: this.item,
        })
        .then(() => this.close())
        .catch((error) => console.log(error));
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.$store.dispatch("maquinaria/findProductosMaquinaria");
    this.item = this.itemParam;
    const id = this.$route.params.id;
    if (id === undefined) return;
    this.item.prprcId = id;
  },
  computed: {
    ...mapState({
      maquinaria: (state) => state.maquinaria.maquinarias,
      loadingSave: (state) => state.tprocesos.loadingSave,
    }),
    formTitle() {
      return this.findTipoProductoPlanilla(this.type);
    },
    buttonTitle() {
      return this.itemIndex === -1 ? "Guardar" : "Actualizar";
    },
    productosEntrada() {
      return this.maquinaria
        .slice()
        .sort((a, b) =>
          a.sinv_pro_descripcion > b.sinv_pro_descripcion
            ? 1
            : b.sinv_pro_descripcion > a.plcsinv_pro_descripciontrNombre
            ? -1
            : 0
        );
    },
    iconTitle() {
      return this.itemIndex === -1 ? "mdi-plus" : "mdi-pen";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>
